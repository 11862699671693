body {
    margin: 0px;
}

.container,
.footer-button {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.navbar-profile {
    height: 60px !important;
    top: 0px;
}

.profile-font {
    font-family: 'Poppins';
}

.is-invalid {
    /* border-color: 2px solid red;
    border-radius: 4px;
    line-height: 1;
    padding: 2px 0 6px 6px;
    background: #ffe6eb; */
    background-color: #45a049;
}

.pointer-events-none {
    pointer-events: none;
}

.outlet-page h3 {
    font-size: 1.3rem !important;
}

.outlet-page .price-bx .price i {
    font-size: 25px;
    margin-right: 8px;
}

.my-token-page .wishlist-bx p {
    margin: 5px;
}

.my-token-page .wishlist-bx .dz-meta i {
    font-size: 17px;
    margin-right: 8px;
}

.my-token-page .wishlist-bx .dz-meta .price {
    font-size: 13px;
}

.dashboard-page .categories-swiper .swiper-slide {
    margin-right: 20px;
}

.outlets-page .cart-list .cart-item .item-foot ul li {
    font-size: 13px !important;
}

.favourite-page .wishlist-bx .price {
    font-size: 14px;
}

.outlet-sign-up textarea.form-control {
    height: 115px !important;
}

.outlet-sign-up input[type="file"].form-control {
    height: 30px !important;
}

.show-pass-conf {
    position: absolute;
    right: 20px;
    top: 18px;
}

.show-pass-conf:after {
    content: "";
    background-color: var(--theme-secondary-color);
    width: 2px;
    height: 20px;
    position: absolute;
    top: 2px;
    left: 11px;
    transform: rotate(40deg);
    display: block;
}

.show-pass-conf.active:after {
    display: none;
}

.show-pass-conf [fill] {
    fill: var(--theme-secondary-color);
}

.scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;

    white-space: nowrap;
}

.scrolling-wrapper::-webkit-scrollbar {
    display: none;
}

.scrolling-wrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.favoriteCardWidth {
    margin-right: 50px !important;
}

.margin-25 {
    margin-bottom: 25px;
}

.outlet-btn {
    color: wheat;
    background-color: indianred;
}

.padding-0 {
    padding-left: 0 !important;
    margin-left: 0 !important;
    margin-right: 50px !important;
}

.paddingBottom {
    padding-right: 7px;
    display: flex;
    align-items: center;
}

.pencilWidth {
    padding-right: -15px;
    margin-right: -25px;
}

.react-confirm-alert {
    max-width: 350px !important;
    display: flex;
    justify-content: center;
}

.extraSpace {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.smallText {
    font-size: 14px !important;
    font-weight: 600;
}

.dashboard-container .card-overlay {
    width: 48%;
    height: 150px;
    border-radius: 10px;
}

.me-2 {
    margin-right: 10px;
}

.filter-btn-clicked {
    background-color: white !important;
    border: 1px solid #ebebeb;
}

.navbar-inner-padding {
    padding-left: 0px !important;
}

 
.danger {
    color: red;
}

.outlet-swiper .swiper-slide {
    width: 80px !important;
}

.outlet-swiper .swiper-slide .item-bx .dz-media img { 
    max-width: 100%;
    max-height: 100%;
    border-radius: 0px;
    object-fit: cover;
}

.outlet-swiper .swiper-slide .item-bx .dz-media {
    padding-bottom: 10px;
    margin-bottom: 0;
}

.outlet-swiper .swiper-slide .item-bx .dz-info {
    padding-right: 2px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.outlet-swiper .swiper-slide .item-bx .dz-info .dz-head h6 {
    font-size: 0.9rem;
}

.outlet-swiper .swiper-slide .item-bx.item-grid {
    margin-bottom: 15px;
}

.toolbar-bottom {
    position: fixed;
    bottom: 0px;
}

.pd-12 {
    padding: 12px !important;
}

.w-55 {
    width: 55% !important;
}

.w-42 {
    width: 42% !important;
}

.text-custom-blue {
    color: #2196f3 !important
}

.disabled {
    opacity: 0.4;
    pointer-events: none;

}

.pb-2 {
    padding-bottom: 17px !important;
}

.custom-image {
    width: 20px;
    margin-left: 5px;
}

.rate {
    margin-top: 5px;
}

.dz-meta-padding-top {
    margin-top: 7px;
}

.refresh-padding {
    margin-left: 80px;
}

.refresh-padding {
    background-color: #fff;
    text-align: center;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    position: relative;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.btn-qr-code {
    height: 58px;
    width: 58px;
    line-height: 70px;
    background-color: var(--f7-theme-color-white);
    border-radius: 22px;
    text-align: center;
    display: block;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    right: 0;
}

.item-bx-qr {

    display: flex;
    justify-content: space-between;
    align-items: center;

}

.account-area .title-head,
.mb {
    margin-bottom: 0px;
}

.qrFile {
    width: 87% !important;
}

.left-qr {
    padding-right: 126px;
}

.mb-1 {
    margin-bottom: 9px !important;
}

.mt-1 {
    margin-top: 7px !important;
}

.item-mb,
.mb {
    margin-bottom: 0px !important;
}

.back-arrow {
    margin-bottom: 8px !important;
    margin-right: 5px !important;
}

.wishlist-bx .dz-media img {
    width: 100px !important;
    height: 100px !important;
    display: flex;
    object-fit: cover;
}

.wrapper-text {
    word-wrap: break-word;
}

.padding-none {
    text-align: center;
    line-height: 60px;
    width: 87%;
    padding: 0px 20px !important;
}

.client-outlet-img img {
    width: 100px !important;
    height: 100px !important;
    border-radius: 10px !important;
}

.d-flex-arrow {
    display: flex;
}

.p-0 {
    padding: 0px;
    line-height: 0px !important;
}

.store-card .time {
    line-height: 0px;
}

.store-card .card-media img {
    height: 141px !important;
}

.store-card .outlet-info {
    padding: 20px 20px 12px 20px;
}

.store-card .outlet-info {
    padding: 10px 15px !important;
}

.store-card .token-info {
    padding: 7px 15px;
}
.margin-3{
    margin-bottom: 5px;
}
.m-ticket {
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 5px;
}


.m-ticket:before {
    content: "";
    position: absolute;
    left: -10px;
    top: 41%;
    background: #eee;
    box-shadow: inset 0 0 25px #cecece;
    width: 17px;
    height: 17px;
    border-radius: 50%;
}


.m-ticket:after {
    content: "";
    position: absolute;
    right: -10px;
    top: 41%;
    background: #eee;
    box-shadow: inset 0 0 25px #cecece;
    width: 17px;
    height: 17px;
    border-radius: 50%;
}


.m-ticket-vt {
    position: absolute;
    right: 7px;
    background-color: var(--f7-theme-color);
    padding: 5px;
    color: #fff;
    border-radius: 10px;
    font-size: 12px;
    text-align: center;
    margin: 0;
    line-height: 20px;
    top: 7px;
}
.m-ticket-vt strong{
    display: block;
    width: 100%;
    font-size: 18px;
    line-height: 18px;
}


.m-ticket>.movie-details {
    display: flex;
    /* gap: 20px; */
    padding:15px 15px 10px 15px;
    width: 100%;
    box-sizing: border-box;
}
.m-ticket .movie{
padding-left: 15px;
width: 65%;
}
.m-ticket>.movie-details>.poster {
    width: 90px;
    height: 90px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgb(136, 136, 136,.5);
    object-fit: contain;
}

.m-ticket>.movie-details>.movie>h4 {
    text-transform: capitalize;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 5px;
    width: 70%;
    line-height: 24px;  
}


.m-ticket>.movie-details>.movie>p {
    font-size: .80em;
    line-height: 12px;
    color: #777;
    font-weight: 450;
    margin-top: 0px;
}

.m-ticket>.ticket-details {
    display: flex;
    gap: 20px;
    padding: 20px 20px;
}


.m-ticket>.ticket-details>.scan {
    width: 100px;
    height: 100px;
}

.m-ticket>.ticket-details>.ticket {
    text-align: center;
    width: 200px;
}


.m-ticket>.ticket-details>.ticket>p {
    font-size: .80em;
    line-height: 19px;
    color: #777;
}

.m-ticket>.ticket-details>.ticket>b {
    margin-top: 10px;
    display: inline-block;
    font-size: 1.2em;
    font-weight: 400;
}


.m-ticket>.ticket-details>.ticket>h6 {
    text-transform: uppercase;
    font-weight: 100;
    font-size: .95em;
    margin-top: 10px;
}


.m-ticket>.info-cancel {
    width: 100%;
    background: #eee;
    color: #888;
    padding: 5px 0px;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
}
.m-ticket .row{
    margin-top: 5px;
}
.m-ticket .link{
    font-weight: 600;
}
.m-ticket .text-theme{
    color: var(--f7-theme-color);
}
.m-ticket .text-danger{
    color: #ff3b30;
}
.m-ticket .item-content{
    margin: 0px !important;
}
.m-ticket   .smalltext{
    font-size: 12px;
    margin-top: 5px;
}
.m-ticket .form-control{
    padding: 10px !important;
    border-radius: 10px;
    font-size: 14px !important;
    line-height: 16px;
    border: 1px solid;
    border-color: #d5d5d5;
    height: auto !important;
}

.m-ticket>.total-amount {
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;
    font-weight: 700;
    font-size: .90em;
    width: 100%;
}
.m-ticket>.total-amount p{
    margin: 5px 0px;
}
.m-ticket .OngoingToken{
    font-size: 13px;
    font-weight: 500;
    width: auto;
    display: block;
}
.m-ticket .OngoingToken strong{
    display: block;
    width: 100%;
    text-align: left;
    font-size: 16px;
}
.mr-clock {
    margin-right: 10px;
}

.text-center {
    margin: auto;
    margin-bottom: 20px;
}

.ms-1 {
    margin-left: 10px;
}

.m-ticket>.movie-details>.movie>p.outlet-address {
    line-height: 17px;
}

 
.m-ticket>.info-cancel {
    margin-bottom: 0px;
    margin-top: 0px;
}

.token-card-media {
    width: 90px !important;
    min-width: 90px !important;
}

.store-card .token-card-media img {
    height: 90px !important;
    width: 90px !important;
}

.token-info .dz-meta ul li {
    font-size: 15px;
}

 

.rating-info .review-star li {
    font-size: 14px;
}

.rating-info .review-star {
    padding: 0;
    margin: -6px -3px;
}

.rating-info .review-star li {
    padding: 2px;
}

.review-info {
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search-list-bar .item-content .item-inner .item-text {
    color: #777777 !important;
    margin-top: 5px;
}

.list .item-feedback-text {
    font-size: 14px;
    font-weight: 500;
    color: #777777 !important;
    ;
    line-height: var(--f7-list-item-text-line-height);
}

.search-list-bar .feedback-content {
    padding: 15px 0 0px 30px !important;
}

.review-info-content {
    margin: 20px 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.get_token_color {
    color: #777777 !important;
}

#no-users-margin {
    margin-left: 30px !important;
    margin-top: 4px !important;
}

.daily-token-mt-0 { 
    margin-left: 0px;
}

.daily-token-m-0 {
    margin: 0px !important; 
}

.margin-top-30{
    margin-top: -45px !important;
    margin-right: -30px;
}

.daily-token-mb-0 {
    margin-bottom: -40px !important;
}

.star-mb {
    margin-top: 1px;
}

.review-m-0 {
    margin: 0px !important;
    margin-top: 0px !important;
    margin-bottom: -26px !important;
}

.confirm-title {
    font-size: 17px;
    line-height: 0.5 !important;
    margin-bottom: 0px !important;
}

.red-button {
    background-color: rgb(211, 75, 75) !important;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
}

.gray-button {
    background-color: gray !important;
    color: white;
    border: none;
    padding: 11px 20px !important;
    margin: 5px;
    cursor: pointer;
}

.react-confirm-alert-body {
    font-family: Arial, Helvetica, sans-serif;
    width: 400px;
    padding: 30px;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #666;
}

.react-confirm-alert-body>h1 {
    font-size: 19px;
    line-height: 0.9 !important;
}

.react-confirm-alert-button-group {
    margin-top: 15px !important;
}

.get-token-icon {
    margin-bottom: -5px;
}

.search-list-bar .item-content {
    padding:5px 0 10px 15px;
}

.ratingPadding {
    margin-left: 8px;
}

.list .item-content {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    min-height: calc(var(--f7-list-item-min-height) - var(--menu-list-offset));
    padding-left: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left) - var(--menu-list-offset));
}

.text-danger {
    color: red !important;
}

.item-inner-grid {
    display: flex !important;
    justify-content: space-between !important;
}

.daily-token-email-svg {
    margin-bottom: -10px !important;
}

.searchbar-container {
    padding-left: 20px !important;
    padding-right: 20px !important;

}

.overflow-hidden {
    overflow: hidden;
}

.daily-token-search-m {
    margin: 5px 0 5px !important;
}

.date-daily-token-input {
    width: 100% !important;

}

.searchbar-input-wrap-pad input {
    padding: 12px 20px 12px 20px !important;
}

.leftSide-email-token {
    margin-left: 10px;
}

.daily-token-inq-image {
    width: 16px;
}

.daily-token-tick-image {
    width: 13px;
}

.daily-token-text {
    font-size: 16px !important;
}

.text-12 {
    font-size: 13px;
}

.time-text {
    color: #968b96;
    text-align: center;
    align-items: center;
    align-self: center;
    margin-left: -5px;
    font-size: 17px;
    font-weight: 500;
}

.page,
.page-baground-color {
    background: #EDEDF5 !important;
}

.dot-circle {
    margin-left: -30px;
}

.info-container {
    display: flex;
}

.left-content {
    order: 2;
    margin-left: 65px;
}

.right-content {
    order: 1;
}

.profile-wraper .bg-profile-info {
    height: 75%;
    min-height: auto;
}

.me-1 {
    margin-right: 6px;
}

.my-token-date {
    font-size: 13px;
}

.store-card .token-card-media img {
    height: 100% !important;
    width: 90px !important;
}

 

.m-ticket {
    width: 100% !important;
}

@media screen and (min-width: 768px) {
    .m-ticket {
        width: 49% !important;
    }
}

.info-bottom-mb {
    margin-bottom: 0px;
    margin-top: 0px;
}

.notification-status {
    color: #37b7af !important;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    position: fixed;
    left: 50%;
    bottom: 65px;
    transform: translate(-50%, -50%);
    margin: 0 auto;

    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    width: 1014px;
    background-color: #fff;
    z-index: 1;
}

.pagination li {
    margin: 0 5px;
    cursor: pointer;
}

.pagination .active {
    font-weight: bold;
}

.text-cancel {
    color: indianred !important;
}

.text-inQueue {
    color: #FFB400 !important
}

.text-remove {
    color: #525252 !important
}

.profile-wraper .profile-content .GreenDot {
    display: block;
}


.store-card .card-media .imgCard {
    height: 125px !important;
}

.info-bottom-pad {
    margin-bottom: 8px;
}

.No-review {
    margin: -5px 0px;
}

.search-list-bar .item-content-review {
    padding: 15px 0 0px 30px;
}

.margin-left-1 {
    margin-left: 5px;
}

.list {
    margin: 10px 0;
}

.text-danger-soft {
    color: #e03b3b;
}

.small-icon {
    font-size: 12px;
    margin-right: 5px;
}

.small-icon-outlet {
    font-size: 13px;
    margin-right: 5px;
}

 
.write-reviews-box .form-elements {
    height: 75px !important;
}

.box-content textarea.form-control {
    height: 79px !important;
}

.footer-button-height {
    height: 35px !important;
}

.time-text {
    color: #ffffffed;
}

.blue-dot {
    color: #007aff;
}

.fav-meta ul li:after {
    content: none !important;
}

.title-container {
    max-width: 100%;
    overflow: hidden;
}

.responsive-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 768px) {
    .responsive-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.download-ms {
    margin-left: 10px;
}

.notification-pagination>.pagination {
    position: fixed;
    bottom: 65px;
    width: 100%;
    /* background-color: white; */
    z-index: 1000;
    display: flex;
    justify-content: center;
    padding: 10px;
    padding: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    /* height: 46px; */
    content: "";
    margin-top: 0;
    margin-bottom: 0;
    width: 1024px;
    background-color: #fff;
}
.notification-list ul li .item-title {
    margin: 0 0 5px;
}
#video {
    top: 0px;
    left: 0px;
    z-index: 1;
    box-sizing: border-box;
    border: 50px solid rgba(0, 0, 0, 0.4);
    box-shadow: rgba(255, 0, 0, 0.5) 0px 0px 0px 1px inset;
    position: absolute;
    width: 100%;
    height: 100%;
}

.reports-row>[class*='col-'] {
    box-sizing: border-box;
    width: calc((49% - 16px * (1 - 1)) / 1) !important;
}

.toast-row {
    display: flex;
    align-items: center;
    background: #fff;
    color: #363636;
    line-height: 1.3;
    will-change: transform;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
    max-width: 350px;
    pointer-events: auto;
    padding: 8px 10px;
    border-radius: 8px;
}

.toast-row .text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(17 24 39/1);
}

.font-medium {
    font-weight: 500;
}

.toast-row .text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128/var(--tw-text-opacity));
}

.toast-row .pos-right{
    position: relative;
    left: 99px
}

.text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
    margin-bottom: -3px;
    margin-top: -1px;
}

.mt-1 {
    margin-top: 0.25rem;
}

.text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgb(79 70 229/1);
}

.font-medium {
    font-weight: 500;
}

.p-4 {
    padding: 1rem;
}

.border-transparent {
    border-color: transparent;
}

.border {
    border-width: 1px;
}

.rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.rounded-none {
    border-radius: 0;
}

.justify-center {
    justify-content: center;
}

.items-center {
    align-items: center;
}

.w-full {
    width: 100%;
}

.flex {
    display: flex;
}

/* a, a:focus, button, button:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
a, button {
    --tw-ring-color: rgb(72 35 7/var(--tw-ring-opacity));
    --tw-ring-opacity: 0.3;
    --tw-ring-offset-color: #14532d;
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .1s;
}
[role=button], button {
    cursor: pointer;
}
[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
}
button, select {
    text-transform: none;
} */

.custom-button {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-color: rgb(72 35 7/var(--tw-ring-opacity));
    --tw-ring-opacity: 0.3;
    --tw-ring-offset-color: #14532d;
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .1s;
    cursor: pointer;
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
    text-transform: none;
    color: #30304d;
    font-weight: bold;
}

.badge {
    /* position: 'absolute'; */
    bottom: 35px;
    left: 10px;
    background-color: indianred;
    border-radius: 50%;
}

.notify-badge {
    margin-bottom: -22px;
}

.page-content .searchbar.search-box .search-bar-width input {
    width: 285px;
}

.search-btn {
    border: 1px solid #ebebeb;
    height: 60px;
    justify-content: end;
    align-self: self-end;
    font-size: 16px;
    color: #30304d;
    background-color: white;
    border-radius: 22px;
    font-weight: 400;
    width: 95px;
}

.custom-toast-error {
    background-color: yellow;
    color: black;
}

.user-margin {
    margin: 20px;
}

.filter-bx .filter-btn-glass path[fill],
.filter-bx .filter-btn-glass path {
    fill: #fff !important;
}

.filter-btn-lf {
    margin-left: 5px !important;
}

.margin-left-upi{
    margin-left: 7px;
    margin-bottom: 15px;
}
.underline{
    text-decoration: underline;
}

.no-notification-msg{
    color: black;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 25px;
}

.no-fav-msg{
    color: black;
    font-size: 1rem;
    font-weight: 600;
    margin-top: -16px;
}

.no-token-msg{
    color: black;
    font-size: 1rem;
    font-weight: 600;
    margin-top: -7px;
}

.share-Icon{
    margin-left: 3px;
}

.p-1{
    padding-left: 10px !important;
}

.show-old-pass {
    position: absolute;
    right: 20px;
    top: 18px;
}

.show-old-pass:after {
    content: "";
    background-color: var(--theme-secondary-color);
    width: 2px;
    height: 20px;
    position: absolute;
    top: 2px;
    left: 11px;
    transform: rotate(40deg);
    display: block;
}

.show-old-pass.active:after {
    display: none;
}

.show-old-pass [fill] {
    fill: var(--theme-secondary-color);
}

.marginEnd-Filter {
    margin-right: 5 px !important;
}

.user-list ul{
    height: auto;
}

.notification-button{
    padding: 4px 6px;
    font-size: 10px;
    text-decoration: underline;
    color: rgb(8, 4, 4) !important;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-size: 12px;
    white-space: nowrap;
}

.notification-button2{
    padding: 1px 1px;
    font-size: 10px;
    text-decoration: underline;
    color: rgb(8, 4, 4) !important;
    border-radius: 10px;
    font-size: 12px;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    white-space: nowrap;
}

.notification-button-size {
    display: flex;
    align-items: center;
  }
  
  .notification-center{
    text-align: center !important;
  }

  .notification-button {
    margin-right: 5px;
  }

  .noreviews-margin{
    font-weight: 600;
    color: #000 !important;
    margin-top: 7px;
    margin-left: 20px;
  }
  
  .nofavrite-margin{
    font-weight: 600;
    color: #000 !important;
    margin-top: -3px;
    margin-left: 20px;
  }

  .text-align-right {
    text-align: right; 
  }

  .custom-dark-blue {
   color: #1a6e4e !important;
  }
  
  .custom-dark-orange {
    color: #166392 !important;
   }
   
 .custom-dark-green {
   color: #adc719 !important;
  }

  .margin-bottom-0{
    margin-bottom: 0px !important;
  }

  .margin-0{
    margin: 0px !important;
  }

  .navbar-bg-theme{
    background: #655FFF !important;
  }

  .navbar-title-theme{
    color: #ffff !important;
  }

  .text-white{
    color: white !important;
  }

.no-overflow {
    overflow: visible !important; 
    text-overflow: inherit !important;
  }

  .first-column {
    width: 150px; 
  }

  .pagination li {
    font-size: 15px !important;
}

.mb-list{
    margin-bottom: 0px !important;
}

.ps-1{
    padding:0px 4px !important;
}

.mt-15{
    margin-top: 15px !important;
}
.mb-15{
    margin-bottom: 20px !important;
}
  
.m-0{
    margin: 0 !important;
}
.mb-5-minus{
    margin-bottom: -5px !important;
}

.review-list-padding{
padding: 8px 15px !important;
}

.logo-request {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .logo-request img {
    width: 140px;
    height: auto;
    margin: -44px -20px -31px 0px ;
    padding: 0;
  }
  
  .request-file{
    margin-left: 10px ;
    margin-bottom: -30px !important;

  }

 .outlet-list-m {
     margin-bottom: 0px !important;
 }
  .review-m{
    margin: -1px 0px -4px 0px !important;
  }

.outlet-list-m.tab-style-2 .swiper-slide {
    padding: 0px 5px 5px 5px !important;
}

@media screen and (max-width: 767px){
    .container, .footer-button {
      padding-left: 15px !important;
      padding-right: 15px !important;
    } 
    .searchbar-container {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    #no-users {
        margin: 20px !important;
        color: #000 !important;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.4;
      }
      
      .searchbar-input-wrap-pad input {
        padding: 12px 20px 12px 15px !important;
    }
    .daily-token-text {
        font-size: 14px !important;
    }
  }
  @media screen and (max-width: 330px){
    .m-ticket>.movie-details>.poster {
        width: 70px;
        height: 70px;
    }
    .m-ticket .movie {
        padding-left: 15px; 
    }
  }

/* NoInternet.css */
.no-internet-container {
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.illustration {
    width: 100%;
    max-width: 220px;
    margin-bottom: 20px;
}

.illustration-heading {
    color: #333;
}

.illustration-paragraph {
    color: #666;
    margin-bottom: 30px;
}

.illustration-button {
    background-color: #4caf50;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.illustration-button:hover {
    background-color: #45a049;
}
  .margin-top-5{
    margin-top: 4px !important;
  }

    .tooltip-container {
        position: relative;
        display: inline-block;
      }
      
      .tooltip-text {
        visibility: visible;
        width: 80px;
        background-color: #666;
        color: white;
        text-align: center;
        border-radius: 6px;
        padding: 3px;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: calc(100% + 5px);
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity 0.3s;
        font-size: 12px;
      }
      
      .tooltip-container:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
      }
      .color-white{
        background-color: #fff;
      }

      .margin-left-24{
        margin-left: 60px !important;
      }

      .stat-box-pad{
        padding: 0px !important;
      }

      .token-item-text{
       position: relative;
       margin-top: 0px !important;
       margin-bottom: 0px !important;
       color: #777777 !important;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: inherit;
        display: -webkit-box;
        font-size: var(--f7-list-item-text-font-size);
        font-weight: var(--f7-list-item-text-font-weight);
        color: var(--f7-list-item-text-text-color);
        line-height: var(--f7-list-item-text-line-height);
        max-height: calc(var(--f7-list-item-text-line-height)* var(--f7-list-item-text-max-lines));
      }

      .search-list-bar .item-content-pading {
        padding: 5px 0 2px 8px !important;
    }
    
    .text-amount-color{
        color: #000 !important;
        font-weight: 500;
    }

    .inner-sh::before{
        font-family: 'framework7-core-icons';
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    text-align: center;
    display: block;
    width: 100%;
    height: 100%;
    font-size: 20px;
    position: absolute;
    top: 50%;
    width: 8px;
    height: 14px;
    margin-top: -7px;
    font-size: var(--f7-list-chevron-icon-font-size);
    line-height: 14px;
    color: var(--f7-list-chevron-icon-color);
    pointer-events: none;
    right: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right));
    content: var(--f7-list-chevron-icon-right);
    }

    .low-balance{
        color: indianred;
    }

    .font-size-20{
        font-size: 20px !important;
    }
    .font-size-14{
        font-size: 14px !important;
    }
   .container-padding-7{
    padding-left:7px !important ;
    padding-right: 7px !important;
    margin-top: 0px !important;
   }

   .card-mt-0{
    margin-top: 0px !important;
   }

   .btn-info-color{
    --f7-button-bg-color: #fff;
    border: 1px solid #a33ac5 !important;
    color: #a33ac5 !important;
   }

   .recommented-text{
    font-size: 12px !important;

   }

  .amount-input{
    border-radius: 4px;
    border: 1px solid #c1baba;
    padding: 6px 4px;
    margin-left: -13px !important;
  }

  .balance-button{
    background: #9d9a9a;
  }
  .wallet-title-color{
    color: #000 !important;
  }

  .badge-oven{
    border-radius: 5px !important;
    margin-top: 2px !important;
    font-weight: 500;
    font-size: 13px !important;
    color: white !important;

  }

  .bg-org-removed {
      padding: 2px 3px;
      border-radius: 5px !important;
      background: #fb6705 !important;
  }

  .bg-red-cancelled {
      padding: 2px 3px;
      border-radius: 5px !important;
      background: #d63939 !important;
  }

  .bg-blue-ongoing {
      padding: 2px 3px;
      border-radius: 5px !important;
      background: #4299e1 !important;
  }

  .bg-green-completed {
      padding: 2px 3px;
      border-radius: 5px !important;
      background: #2fb344 !important;
  }

  .title-space {
      text-align: right !important;
      margin-left: -34px !important;
  }

  .border-bottom-color {
      border-top: 1px solid rgb(218, 216, 216);
  }

  .item-inner-grid-space {
      display: flex !important;
      justify-content: end !important;
  }

  .customer-text{
    font-size: 15px !important;
  }

  .customer-value-text{
    font-size: 14px !important;
  }

  .wallet-card-padding{
    padding: 0px !important;
  }
  .w-49 {
    width: 49% !important;
}
.w-83{
    width: 83% !important;
}
.logo-request-singpage{
    width: 100% !important;
}

.logo-request-singpage {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .logo-request-singpage img {
    width: 140px;
    margin-top: -28px !important;
  }

  .logo-space{
    padding: 25px 0px 70px;
    background: #655FFF;
  }
  .logo-space .logo-request-singpage img {
    width: 250px; 
    margin-top: 0px !important;
  }
  @media screen and (max-width: 767px){
    .logo-space .logo-request-singpage img {
        width: 200px;  
      }
    
}
@media screen and (max-width:1024px){
    .logo-space{ 
        padding: 20px 0px 45px;
    }
}

  .text-heading-token{
    margin-bottom: 5px;
    font-size: 14px;
    color: #000;
    font-weight: 600;
}

.containerCard-height {
    text-align: center; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; 
}

.rt-page-baground-color{
    background: #655FFF !important;
}

.height-100{
    text-align: center; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90px !important;
}

.mb-0{
    margin-bottom: 0px !important;
}
.client-menu-card, .client-menu-border-rad {
    border-radius: 5px 5px 5px 5px !important;

}
.client-menu-card .inner-page{
padding-bottom: 0px !important;

}

.rate-hd{
    margin: 13px 0px 0px 45px !important;
}
.rate-title{
    margin: 0px 0px 0px -17px!important;
}

.description-title{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.menu-item-card .item-content {
    padding:  4px 0px 4px 15px;

}

.stepper-margin{
    margin:3px -9px -6px 1px !important
}

.item-title-center{
    display: flex;
    justify-content: center; 
    align-items: center;
}

.title-center-menu {
     margin: 10px 5px!important; 
    text-align: center !important;
}

.title-center-qnty {
    margin: 0px 25px 0px 0px!important; 
   text-align: center !important;
}

.inner-page-pad-0, .inner-page{
    padding-bottom: 0px !important;
}
.ms-15{
margin-left: 15px !important;
}

  
.list .item-title-popup {
    min-width: 0;
    flex-shrink: 1;
    white-space: var(--f7-list-item-title-white-space);
    position: relative;
    overflow: hidden;
    font-size: 14px !important;
    max-width: 100%;
    font-size: var(--f7-list-item-title-font-size);
    font-weight: var(--f7-list-item-title-font-weight);
    color: var(--f7-list-item-title-text-color);
    line-height: var(--f7-list-item-title-line-height);
}

.title-gray{
    color: gray !important;
    
}

.text-font-size{
    overflow-x: hidden;
    height: 100%;
    position: relative;
    color: #777777;
    max-width: 100%;
    font-size: 14px !important;
    font-weight: 600 !important;
    font-family: "Poppins", sans-serif;
    background: #fff;
    -webkit-font-smoothing: antialiased;
}

.title-font-size{
    overflow-x: hidden;
    height: 100%;
    position: relative;
    color: #000 !important;
    max-width: 100%;
    margin-left: -5px !important;
    font-size: 15px !important;
    font-weight: bold !important;
    font-family: "Poppins", sans-serif;
    background: #fff;
    -webkit-font-smoothing: antialiased;
}

.rate-hd-popup{
    margin: 13px 0px 0px 45px !important;
}

.rate-popup{
    margin: 13px 0px 0px 7px !important;
}

.font-size-des{
  font-size: 13px !important;
}

.grandtotal-margin{
margin: -3px 10px !important

}

.grand-total-height{
    height: 29px !important;
}

.popover-close {
text-align: center;
align-items: center;
margin-bottom: -5px !important;
}

.scrollable {
    max-height: 170px !important;

    overflow-y: auto;
    overflow-x: scroll;
  }
  
  .title-bottom-border{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
  }

  .title-pading{

    margin: 0px 0px !important;
  }

  .height-0{
    height: 0px !important;
  }

  .list .menu-item-inner {
    min-height: 25px !important;
  }

  .list .menu-item-name{
    width: 125px !important;
  }

  @media screen and (max-width: 440px) {
    .popover-menu {
      top: 20%;
    }
  }
 
  .z-index-dropdown{
    z-index: 5000 !important;
  }

  .autoSerachDropdown .Icon {
    position: absolute;
    top: 2px;
    right: 3px;
    height: 92%;
    width: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    background: #fff;
}

.autoSerachDropdown .Icon img {
    height: 20px;
}

.autoSerachDropdown .Icon.closeIcon img {
    height: 15px;
}

.autoSerachDropdown .dropdown-menu {
    width: 100%;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 7%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 8px 9px 1px rgb(0 0 0 / 0%);
    -webkit-box-shadow: 0 5px 5px -3px rgb(0 0 0 / 7%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 8px 9px 1px rgb(0 0 0 / 0%);
    -moz-box-shadow:0 5px 5px -3px rgb(0 0 0 / 7%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 8px 9px 1px rgb(0 0 0 / 0%);
    border: 0px;
    padding: 0;
    overflow: hidden;
    border-radius: 4px;
    background-color: #fff;
    transform: inherit !important;
    inset: 55px auto auto 0px !important;
}


.autoSerach-list li {
    width: 100%;
    margin-left: -30px  !important;
}


.autoSerach-list li .listing:hover,
.autoSerach-list li .listing:focus {
    background-color: #ecedf3;
}

.autoSerach-list li:last-child .listing {
    border-bottom: 0px;
}

.autoSerach-list .productImg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    background-color: rgba(16, 30, 98, 0.08);
    border-radius: 50%;
    overflow: hidden;
}

.autoSerach-list .productImg img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.autoSerach-list .producInfo .tittle {
    font-size: 18px;
    font-weight: 800;
    line-height: 18px;
    letter-spacing: normal;
    color: #555;
    margin-bottom: 2px;
}

.autoSerach-list .producInfo .smallText {
    display: block;
    width: 100%;
    font-size: 16px;
    font-weight: normal;
    color: #757575;
}

.searchMain{
    width: 100%;
    padding: 10px !important;
}

ul {
    list-style-type: none !important;
}

.search-text-user {
    font-size: 13px;
    color: #958d9e;
}

.position-relative {
    position: relative;
  }
  
  .floating-card {
    position: absolute;
    top: calc(80% + -10px); 
    left: 0;
    z-index: 10000;
  }
  
.card-body {
    padding: 10px;
}

.list-group {
    margin: 0;
    padding: 0;
}

.list-group-item {
    cursor: pointer;
}

.list-group-item:hover {
    background-color: #f8f9fa;
}

.searchtextlist ul ul {
    padding-left: 0px !important;
}
  
.form-floating{
    height: 35px !important;color: #777777 !important;
    padding: 5px !important;
}
.card-border{
    padding: 10px !important;
    border-bottom: 1px solid rgb(0 0 0 / 12%) !important;
    color: #777777 !important;
}
.card-border-phone{
    padding: 10px !important;
    border-bottom: 1px solid rgb(0 0 0 / 12%) !important;
    color: #777777 !important;
}

.input-text-name{
    color: #777777 !important;
}

.card-radius{
    border-radius: 10px !important;
    padding-bottom: 20px !important;
}

#nameTextBox{
    height: 30px !important;
}

.zindex-0 {
    z-index: 0;
}

.m-minus {
    margin-left: -5px !important;
    margin-right: -4px !important;
    padding-left: 5px !important;
}

.drowpdown-text {
    font-size: 16px !important;
    line-height: 10px;
    padding: 5px 5px;
    border-bottom: 1px solid rgb(0 0 0 / 12%) !important;
}

.larger-font {
    font-size: 15px;
}

.m-minus-top {
    margin-top: -13px !important;
}

.h-555{
    height: 100%;
    min-height: fit-content !important;
}
/* .border-teaxtarea{
    border: 1px solid red !important;
} */

.card-border-textarea{
    padding: 10px !important;
    color: #777777 !important;
    width: 100% !important;
}
.card-border-textarea textarea{
    width: 95% !important;
}
.bg-blue-ongoing-dash{
    padding: 2px 3px;
    border-radius: 5px !important;
    background: #4299e1 !important;
    color: white !important;
}

.cityName-title {
    margin: 10px !important;
}

.searchbar-city {
    background-color: #ededf5 !important;
}

.search-city-input {
    margin-bottom: -13px !important
}

.selected-cityName {
    font-weight: bold;
}