/* Notification List */
.notification-list{
	padding: 0;
	ul{
		padding: 0;
		margin: 0;
		li{
			display: block;
			border-bottom: 1px solid rgba(0,0,0,0.1);
			padding: 20px 30px;
			
			.item-title{
				margin: 0 0 10px;
				
				i{
					margin-right: 5px;
					font-size: 12px;
				}
			}
			.item-text{
				font-size:14px;
				margin-bottom: 0;
				line-height: 1.3;
			}
			.item-time{
				font-size: 12px;
				float: right;
				opacity: 0.5;
				font-weight: 600;
			}
		}
	}
}