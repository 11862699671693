/* Sidebar Menu */
.sidebar-logo{
	padding:30px;
	position: relative;
	
	a{
		display:block;
		img{
			width:165px;
		}
	}
}
.sidebar-left{
	&:after{
		content:none;
	}
	.title{
		margin: 0;
		padding: 30px;
	}
	.panel-close{
		display: block;
		color: #000;
		font-size: 28px;
		position: absolute;
		right: 25px;
		top: 32px;
		line-height: 1;
	}
	.nav-bar{
		margin: 0;
		ul{
			margin:0;
			padding:0;
			background-color: var(--f7-theme-color-white);
			
			&:before,
			&:after{
				content: none;
			}
			
			li{
				list-style:none;
				display:block;
				margin: 0;
				padding: 0!important;
				
				a{
					display: flex;
					align-items: center;
					color:#b0acb3;
					font-size:16px;
					padding: 15px 30px;
					position:relative;
					margin: 0;
					border-radius: 0!important;
	
					svg{
						margin-right: 15px;
					}
					i{
						transform: scale(1.4);
						-moz-transform: scale(1.4);
						-webkit-transform: scale(1.4);
						-ms-transform: scale(1.4);
						-o-transform: scale(1.4);
						margin-right: 15px;	
					}
					path[fill],
					path{
						fill: #adadad;
					}
					i{
						color: #adadad;	
					}	
					&.item-selected{
						background:var(--f7-theme-color-light);
						path[fill],
						path{
							fill: var(--f7-theme-color);
						}
						i,
						span{
							color: var(--f7-theme-color);	
						}
					}
				}
			}
		}
	}
	.page-content{
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}
}
.sidebar-footer{
	padding: 20px 30px;
	.name{
		color: #b1b1c3;
		font-weight: 500;
		font-size: 15px;
		margin-top: 0;
		margin-bottom: 5px;
	}
	p{
		color: #b1b1c3;
		font-weight: 400;
		font-size: 12px;
		margin-top: 0;
		margin-bottom: 0;
	}
}