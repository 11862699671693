// Tabbar Styles
.tabbar{

	&.tab-style-1{
		height: 56px;
		background-color: transparent;
		z-index: 9;
			
		.toolbar-inner{
			padding: 5px;
			background-color: #f1f1f1;
			border-radius: 22px;
		}
		
		&:before,
		&:after{
			content:none;
		}
		.tab-link-highlight{
			display: none;
		}
		.tab-link{
			font-size: 16px;
			text-transform: capitalize;
			letter-spacing: unset;
			font-weight: 600;
			width: auto;
			padding: 12px 36px;
			border-radius: 22px;
			height: 44px;
			background-color: transparent;
			
			&.tab-link-active{
				color: $headings-color;
				background-color: var(--f7-theme-color-white);
			}
		}
	}
	&.tab-style-2{
		background-color: transparent;
		margin-left: -30px;
		
		&:before,
		&:after{
			content:none;
		}
		.tab-link-highlight{
			display: none;
		}
		
		.swiper-slide{
			width: auto;
			&:first-child {
				margin-left: 30px;
			}
		}
		
		.tab-link{
			font-size: 18px;
			letter-spacing: unset;
			text-transform: capitalize;
			opacity: 0.5;
			
			&:after{
				content: "\f111";
				font-size: 10px;
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				opacity: 0;
				color: $secondary;
				@include transitionMedium;
			}
			
			&.tab-link-active{
				opacity: 1;
				&:after{
					opacity: 0.8;
				}
			}
		}
		@include respond('tab-port') {
			margin-right: -30px;
		}
	}

}