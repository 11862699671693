::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar-track {
  box-shadow:inset 0 0 0px 5px #fff;
}
 
::-webkit-scrollbar-thumb {
	background-color: rgba(0,0,0,0.2);
}
:root{
	scrollbar-width: thin;
	scrollbar-color: #e1e1e1 #fff;
}














