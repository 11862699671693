// Rewards
.rewards-box{
	padding-top: 90px;
    padding-bottom: 30px;
    border-radius: 0 0 22px 22px;
    text-align: center;
	
	.title{
		margin: 0 0 12px;
		color: #fff;
	}
	p{
		color: #fff;
		margin: 0;
		a{
			color: $secondary;
			font-weight: 600;
			margin-left: 2px;	
		}
	}
	.dz-media{
		position: relative;
		margin-bottom: 30px;
		.badge-lg{
			position: absolute;
			left: 50%;
			bottom: 0px;
			transform: translateX(-50%);
			background-color: #ffaf36;
		}
	}
}

// Rewards Progress
.rewards-progress{
    margin-top: 35px;
	.progress-head{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
		
		h5,
		p{
			margin: 0;
			color: #fff;
		}
	}
	.progress-bar{
		display: flex;
		margin: 0 -4px;
		padding: 0;
		
		li{
			display: inline-block;
			height: 10px;
			border-radius: 20px;
			background-color: rgba(255,255,255,0.1);
			width: 100%;
			margin: 0 4px;
				
			&.active{
				background-color: $secondary;
			}
		}
	}
}

// Rewards Card
.rewards-card{
	display: flex;
    align-items: center;
    padding: 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 22px;
	margin-bottom: 20px;
	justify-content: space-between;
	
	.title{
		margin: 0;
		margin-right: 15px;
		font-weight: 500;
	}
	.badge-lg{
		color: #ffaf36;
		background-color: #fff2de;
	}
}