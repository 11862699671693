/* Theme Color */
:root,
.md:not([class*='color-theme']){
  --f7-theme-color: #4a3749;
  --f7-theme-color-rgb: 74,55,73;
  --f7-theme-color-shade: #3a2739;
  --f7-theme-color-tint: #7D5B7C;
  --f7-theme-color-light: #E5DAE5;
}
.color-theme-red {
  --f7-theme-color: #ff3b30;
  --f7-theme-color-rgb: 255, 59, 48;
  --f7-theme-color-shade: #ff1407;
  --f7-theme-color-tint: #ff6259;
  --f7-theme-color-light: #fbe6e4;
}
.color-theme-green {
  --f7-theme-color: #09c36a;
  --f7-theme-color-rgb: 76, 217, 100;
  --f7-theme-color-shade: #2cd048;
  --f7-theme-color-tint: #6ee081;
  --f7-theme-color-light: #e7f7e9;
}
.color-theme-blue {
  --f7-theme-color: #2196f3;
  --f7-theme-color-rgb: 33, 150, 243;
  --f7-theme-color-shade: #0c82df;
  --f7-theme-color-tint: #48a8f5;
  --f7-theme-color-light: #e7eff6;
}
.color-theme-pink {
  --f7-theme-color: #ff2d55;
  --f7-theme-color-rgb: 255, 45, 85;
  --f7-theme-color-shade: #ff0434;
  --f7-theme-color-tint: #ff5676;
  --f7-theme-color-light: #f6e0e4;
}
.color-theme-yellow {
  --f7-theme-color: #ffcc00;
  --f7-theme-color-rgb: 255, 204, 0;
  --f7-theme-color-shade: #d6ab00;
  --f7-theme-color-tint: #ffd429;
  --f7-theme-color-light: #f2efe4;
}
.color-theme-orange {
  --f7-theme-color: #ff8900;
  --f7-theme-color-rgb: 255, 149, 0;
  --f7-theme-color-shade: #d67d00;
  --f7-theme-color-tint: #ffa629;
  --f7-theme-color-light: #f9f2e8;
}
.color-theme-purple {
  --f7-theme-color: #ad00ff;
  --f7-theme-color-rgb: 156, 39, 176;
  --f7-theme-color-shade: #7e208f;
  --f7-theme-color-tint: #b92fd1;
  --f7-theme-color-light: #f2dbf6;
}
.color-theme-deeppurple {
  --f7-theme-color: #673ab7;
  --f7-theme-color-rgb: 103, 58, 183;
  --f7-theme-color-shade: #563098;
  --f7-theme-color-tint: #7c52c8;
  --f7-theme-color-light: #f0eaf9;
}
.color-theme-lightblue {
  --f7-theme-color: #5ac8fa;
  --f7-theme-color-rgb: 90, 200, 250;
  --f7-theme-color-shade: #32bbf9;
  --f7-theme-color-tint: #82d5fb;
  --f7-theme-color-light: #e2eff5;
}
.color-theme-teal {
  --f7-theme-color: #009688;
  --f7-theme-color-rgb: 0, 150, 136;
  --f7-theme-color-shade: #006d63;
  --f7-theme-color-tint: #00bfad;
  --f7-theme-color-light: #e3f5f3;
}
.color-theme-lime {
  --f7-theme-color: #cddc39;
  --f7-theme-color-rgb: 205, 220, 57;
  --f7-theme-color-shade: #bac923;
  --f7-theme-color-tint: #d6e25c;
  --f7-theme-color-light: #f0f1e5;
}
.color-theme-deeporange {
  --f7-theme-color: #ff6b22;
  --f7-theme-color-rgb: 255, 107, 34;
  --f7-theme-color-shade: #f85200;
  --f7-theme-color-tint: #ff864b;
  --f7-theme-color-light: #fcf2ed;
}
.color-theme-gray {
  --f7-theme-color: #8e8e93;
  --f7-theme-color-rgb: 142, 142, 147;
  --f7-theme-color-shade: #79797f;
  --f7-theme-color-tint: #a3a3a7;
  --f7-theme-color-light: #f5f5f5;
}
.color-theme-white {
  --f7-theme-color: #ffffff;
  --f7-theme-color-rgb: 255, 255, 255;
  --f7-theme-color-shade: #ebebeb;
  --f7-theme-color-tint: #ffffff;
  --f7-theme-color-light: #f5f5f5;
}
.color-theme-black {
  --f7-theme-color: #000000;
  --f7-theme-color-rgb: 0, 0, 0;
  --f7-theme-color-shade: #000000;
  --f7-theme-color-tint: #141414;
  --f7-theme-color-light: #f5f5f5;
}
.color-red {
  --f7-theme-color: #ff3b30;
  --f7-theme-color-rgb: 255, 59, 48;
  --f7-theme-color-shade: #ff1407;
  --f7-theme-color-tint: #ff6259;
  --f7-theme-color-light: #f4e9e9;
}