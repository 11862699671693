// Write Reviews
.write-reviews-box{
	padding-top: 30px;
	textarea.form-control{
		height: 150px!important;
	}
	.reviews-head{
	
		.title{
			margin-top: 0;
			margin-bottom: 10px;
		}
		p{
		    font-size: 14px;
			margin-top: 0;
		}
	}
}

// Rating Info
.rating-info{
	margin: 35px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
	
	.rating-text{
		margin: 0;
	}
	.rating-list{		
		padding: 0;
		margin: -4px 0;
		li{
			display: inline-block;
			font-size: 24px;
			padding: 0 4px;
			color: #FF8730;
		}
	}
}

// Review Product
.review-product{
	text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px 60px;
	
	.dz-media{
		height: 130px;
		width: 130px;
		border-radius: 22px;
		overflow: hidden;
		margin: 0 auto 20px;
			
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
    .title{
		margin: 0 0 10px;
		font-weight: 500;
	}
	p{
		margin: 0;
		font-size: 16px;
	}
}