.container {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
}

.md .list .item-media {
    min-width: unset;
}

/* Home Page */
.back-login{
	.navbar-bg{
		background:var(--f7-theme-color-light);
		
		&:before,
		&:after{
			content:none;
		}
	}
}
.searchbar input[type=search],
.searchbar input[type=text]{
	font-size: 16px;
}

// Badge
.badge-lg{
	height: auto;
	padding: 10px 20px;
	border-radius: 30px;
	color: #fff;
	font-size: 18px;
	line-height: 18px;
	background-color: var(--theme-secondary-color);
	display: inline-flex;
	strong{
		margin-right: 5px;
		font-weight: 600;
	}
}

// Sticky Top 
.sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
}

// Button
.button-light{
    background-color: #E8FFE0;
    color: var(--theme-secondary-color);
}

// border
.border-top{
	border-top: 1px solid;
	border-color: #F4F4F4;
}

// Menubar Area
.menubar-area{
    background-color: var(--f7-theme-color-white);
	height: 60px;
		
	&:before,
    &:after{
        content: none;
    }
    .toolbar-inner{
		max-width: 1024px;
		left: 50%;
		transform: translatex(-50%);
		z-index: 1;
		box-sizing: border-box;
		padding-left: 30px;
		padding-right: 30px;
	
        .tab-link {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: unset;
			color: #adadad;
			letter-spacing: unset;
			position: relative;
			
			path[fill],
			path{
				fill: #adadad;
			}
			i{
				color: #adadad;	
			}			
			span{
				font-size: 12px;
				text-transform: capitalize;
				display: block;
				margin-top: 5px;
			}
			&:after{
				content: "";
				background-color: var(--theme-secondary-color);
				height: 10px;
				width: 10px;
				border-radius: 10px 10px 0 0;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				opacity: 0;
				bottom: -2px;
			}
			&.active{
				path,
				path[fill]{
					fill: var(--theme-secondary-color);
				}
				i{
					color: #adadad;	
				}
				span{
					color: var(--theme-secondary-color);
				}
				&:after{
					opacity: 1;
				}
			}
        }
    }
    .tab-link-highlight{
        display: none;
    }
}

// Swiper Multiple
.tag-swiper.demo-swiper-multiple{ 
	margin-right: -30px;
	height: auto;
	
	.swiper-slide{
		width: auto;
		border: 0;
		background: transparent;
		height: auto;
		display: block;
	}
}

// Title Bar
.title-bar{
	display:flex;
	align-items:center;
	justify-content:space-between;
	font-size:14px; 
	margin-bottom: 15px;
	
	.dz-title,
	a{
		font-weight:600;
		margin: 0;
	}
	path[fill],
	path{
		fill: var(--f7-theme-color);
	}
	i{
		font-size: 24px;
		color: var(--f7-theme-color);
	}
}

// Review List
.review-list{
	margin: 0;
	ul{
		background:transparent;
		
		&:after,
		&:before{
			content:none;
		}
		li{
			.item-link{
				padding-left:0;
				.item-media{
					width: 40px;
					margin-top: 5px;
	
					img{
						width:100%;
						border-radius: 45px;
					}
				}
				.item-inner{
					padding-right:0;
					
					.item-subtitle{
						font-size: 12px;
						margin-bottom: 5px;
						opacity: 0.7;
					}
					.item-text{
						font-size: 14px;
						color: #000;
					}
					
				}
			}
		
		}
	}
}

// Avatar Group
.avatar-group{
	display: flex;
	img{
		width: 32px;
		border: 2px solid #fff;
		border-radius: 50px;
		margin-left: -12px;
		&:first-child{
			margin: 0;
		}
	}
}

// search-list
.search-list{
	ul{
		&:before,
		&:after{
			content: none;
		}
	}
}

// searchbar
.page-content{
	.searchbar{
		&.search-box{
			height: 60px;
			box-shadow: unset;
			margin: 0 0 20px;
			border-radius: 20px;
			width: 100%;
			
			.searchbar-input-wrap{
				input{
					border: 1px solid #ebebeb;
					height: 60px;
					background-color: var(--f7-theme-color-white);
					font-size: 16px;
					padding: 12px 20px 12px 60px;
					border-radius: 22px;
					font-weight: 400;
				}
				.searchbar-icon{
					left: 20px;
					margin: 0;
					top: 18px;
					&:after{
						content: none;
					}
				}
			}
		}
	}
}

// Swiper Slider Wrapper
.swiper-slider-wrapper{
	position: absolute;
    top: 0;
    height: 350px;
	.swiper-slide{
		img{
			width: 100%;
			object-fit: cover;
			height: 100%;
		}
	}
	.swiper-pagination{
		bottom: 50px;
		.swiper-pagination-bullet{
			width: 23px;
			height: 6px;
			background-color: var(--f7-theme-color-white);
			opacity: 0.3;
			border-radius: 5px;
			
			&.swiper-pagination-bullet-active{
				opacity: 1;
			}
		}
	}
}

// Swiper
.card-swiper1{
    margin-left: -30px;
    padding-bottom: 25px;

	.swiper-slide{
		width: auto;
		&:first-child {
			margin-left: 30px;
		}
	}
	.swiper-pagination-bullets{
		bottom: 0;
	}
	.swiper-pagination-bullet {
		width: 16px;
		height: 5px;
		border-radius: 10px;
		@include transitionMedium;
		&.swiper-pagination-bullet-active{
			width: 30px;
		}
	}
	@include respond('tab-land') {
		margin-right: -30px;
	}
}

// Categories
.categories-bx{
	display: block;
	border-radius: 22px;
	background-color: var(--f7-theme-color);
	padding: 24px 20px 30px;
	position: relative;
	overflow: hidden;
	
	.icon-bx{
		position: relative;
		margin-bottom: 20px;
		img,
		svg{
			width: 35px;
		}
		i{
			color: #fff;	
		}
		path[fill],
		path{
			fill: #fff;
		}
	}
	.title{
		margin: 0 0 5px;
		color: #fff;
		a{
			color: #fff;
		}
	}
	.menus{
		color: $secondary;
	}
	.bg-icon{
	    position: absolute;
		right: -30px;
		bottom: -30px;
		width: 100px;
		font-size: inherit;
		opacity: 0.1;
		height: 100px;
		width: 100px;
	}
}

// dz-banner-heading
.dz-banner-heading{
	box-sizing: border-box;
	position: fixed;
	width: 100%;
	height: 300px;
	overflow: hidden;
	iframe{
		border: 0;
		width: 100%;
		height: 250px;
	}
	.bnr-img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

// Fixed Content
.fixed-content{
	background-color: var(--f7-theme-color-white);
    border-radius: 20px 20px 0 0;
    position: relative;
	z-index: 1;
    box-sizing: border-box;
	padding-top: 30px;
	padding-bottom: 35px;

	.cart-list-area{
		margin-left: -30px;
		margin-right: -30px;
	}
}

// dz-banner
.dz-banner {
	height: 250px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
	background-position: bottom;
}
.dz-banner-height{
	height:280px;
	width:100%;
	display:block;
}

// input-select
.input-select{
	position: relative;
    padding-right: 20px;
	&:after{
		content: "\f0dd";
		position: absolute;
		font-family: "Font Awesome 5 Free";
		color: var(--f7-theme-color);
		right: 0;
		top: 0;
		font-weight: 900;
		line-height: 14px;
	}
	select{
		font-size: 14px;
		color: var(--f7-theme-color);
		font-weight: 500;
	}
}

// like Btn
.like-btn{
	.fas{
		display: none;
	}
	&.active{
		.far{
			display: none;
		}
		.fas{
			display: inline-block;
		}
	}
}

// Promotion Swiper
.pro-swiper{
	margin-left: -30px;
    margin-bottom: 40px;

	.swiper-slide{
		max-width: 315px;
		
		&:first-child {
			margin-left: 30px;
		}
	}
	.swiper-pagination-bullets{
		bottom: 0;
	}
	.swiper-pagination-bullet {
		width: 16px;
		height: 5px;
		border-radius: 10px;
		@include transitionMedium;
		&.swiper-pagination-bullet-active{
			width: 30px;
		}
	}
	@include respond('tab-land') {
		margin-right: -30px;
	}
	
	@media only screen and (max-width: 349px) {
		.swiper-slide{
			max-width: 265px;
		}
	}
}
.card-overlay{
	background-color: var(--f7-theme-color);
    padding: 30px 20px;
    border-radius: 22px;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;
    align-items: center;
	position: relative;
	height: 185px;
		
	&:before,
	&:after{
		content: "";
		height: 55px;
		width: 55px;
		background-color: rgba(255,255,255,0.1);
		border-radius: 50%;
		position: absolute;
	}
	&:before{
		left: 50%;
		top: 30px;
		transform: translatex(-50%);
	}
	&:after{
		left: -10px;
		bottom: -20px;
		transform: scale(3);
	}
	
	.title{
		margin: 0;
		font-weight: 600;
		white-space: unset;
		a{
			color: #fff;
		}
	}
	.dz-meta{
		margin-top: 25px;
	}
	.price{
		color: #fff;
		font-size: 24px;
		font-weight: 500;
		
		del{
			color: $secondary;
			font-size: 18px;
			margin-left: 10px;
		}
	}
	
	.dz-media{
		position: relative;
		width: 200px;
		margin: -20px -15px -20px -30px;
		z-index: 0;
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			display: flex;
		}
		&:before,
		&:after{
			content: "";
			position: absolute;
		}
		&:before{
			background-image: url(../img/pattern1.png);
			height: 16px;
			width: 25px;
			bottom: 15px;
			right: 28px;
		}
		&:after{
			height: 55px;
			width: 55px;
			background-color: $secondary;
			border-radius: 50%;
			transform: scale(3);
			right: -20px;
			bottom: -30px;
			z-index: -1;
			opacity: 0.3;
		}
	}
}

// item-bx
.dz-meta{
	ul{
		padding: 0!important;
		display: flex;
		flex-wrap: wrap;
		margin: 0;
		li{
			display: inline-flex !important;
			align-items: center;
			margin-right: 12px;
			color: var(--f7-theme-color);
			font-size: 18px;
			line-height: 18px;
			position: relative;
			padding-right: 15px;
			
			path[fill],
			path{
				fill: var(--f7-theme-color);
			}
			svg{
				margin-right: 10px;
			}
			i{
				color: var(--f7-theme-color);
				margin-right: 10px;
			}
			&:after{
				content: "";
				background-color: #C4C4C4;
				border-radius: 50%;
				height: 5px;
				width: 5px;
				top: 50%;
				transform: translateY(-50%);
				right: 0;
				position: absolute;
			}
			&:last-child{
				margin-right: 0;
				padding-right: 0;
				&:after{
					content: none;
				}
			}
		}
	}
}
.list{
	.item-bx{
		.hidden-by-searchbar{
			display: block!important;
		}
		.tag-list{
			li{
				display: inline-block!important;				
			}
		}
	}
}
.tag-list{
	padding: 0;
	margin-top: 0;
	li{
		display: inline-block;
		position: relative;
		margin-right: 2px;
		a{
			font-size: 14px;
		}
		&:after{
			content: ",";
			right: 0;
			bottom: 0;
			color: inharit;
		}
		&:last-child{
			&:after{
				content: none;
			}
		}
	}
}
.item-list{
	ul{
		background-color: var(--f7-theme-color-white);
	}
}
.item-bx{
	position: relative;
	.dz-media{
		position: relative;
		img{
			border-radius: 22px;
		    width: 100%;
			height: 100%;
			display: flex;
		}
	}
	.item-title{
		font-weight: 600;
		margin: 0 0 8px;
		white-space: normal;
	}
	.price{
		font-weight: 600;
		
		del{
			font-size: 16px;
			font-weight: 500;
			color: #929292;
			margin-left: 12px;
		}
	}
	.tag-list{
		padding: 0;
		margin-bottom: 15px;
	}
	.cart-btn{
		height: 58px;
		width: 58px;
		line-height: 58px;
		background-color: var(--f7-theme-color-white);
		border-radius: 22px;
		text-align: center;
		display: block;
		box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
		position: absolute;
		svg{
			height: 58px;
		}
		path[fill],
		path{
			fill: var(--f7-theme-color);
		}
		i{
			color: var(--f7-theme-color);
		}
	}
	
	&.item-grid{
		margin-bottom: 30px;		
		.dz-media{
			margin-bottom: 15px;
		}
		.cart-btn{
			right: 0;
			bottom: 0;
		}
	}
	
	&.item-list{
		display: flex;
		margin-bottom: 20px;
		.dz-media{
		    margin-right: 20px;
			max-width: 92px;
			
			img{
				border-radius: 12px;
			}
		}
		.item-title{
			margin-bottom: 10px;
		}
		.dz-info{
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			width: 100%;
		}
	}

}
.item-swiper{
	margin-left: -30px;
	
	.swiper-slide{
		width: 205px;
		&:first-child {
			margin-left: 30px;
		}
	}
	.item-bx{
		.dz-info{
			padding-right: 30px;
		}
		.dz-media{
			padding-bottom: 20px;
			margin-bottom: 0;
		}
		.cart-btn{
			right: 20px;
		}
		.tag-list{
			margin-bottom: 12px;
		}
		.item-title {
			margin: 0 0 15px;
		}
	}
	@include respond('tab-land') {
		margin-right: -30px;
	}
}

// Notification Bar
.notification-bar {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 30px;
	
	.title{
		margin: 0;
	}
	span{
		display: block;
		margin-bottom: 5px;
		color: #000;
	}
	.media{
		position: relative;
		
		img{
			height: 48px;
			width: 48px;
			border-radius: 12px;
		}
		
		.active{
			color: var(--f7-theme-color);
			font-size: 15px;
			border: 2px solid #fff;
			position: absolute;
			right: -8px;
			top: -7px;
			border-radius: 50%;
		}
	}
}

// categories-swiper
.categories-swiper{
	margin-left: -30px;
	.swiper-slide{
		width: 147px;
		&:first-child {
			margin-left: 30px;
		}
	}
	@include respond('tab-land') {
		margin-right: -30px;
	}
}

// Filter
.filter-bx{
	display: flex;
	.filter-btn{
		height: 60px;
		min-width: 60px;
		background-color: #ebebeb;
		border-radius: 22px;
		text-align: center;
		display: block;
		margin-left: 12px;

		svg{
			height: 60px;
		}
		path[fill],
		path{
			fill: var(--f7-theme-color);
		}
		i{
			color: var(--f7-theme-color);
		}
	}
}

// location-wraper
.location-wraper{
	iframe{
		border: 0;
		height: 100%;
		width: 100%;
		margin-bottom: -6px;
		position: fixed;
		z-index: -1;
		top: 0;
	}
	.fixed-toolbar{
		position: absolute;
		left: 0;
		right: 0;
		background-color: var(--f7-theme-color-white);
		padding: 5px 30px 20px;
	}
	.swiper-wraper{
		height: 100%;
		display: flex;
		flex-wrap: wrap;
	}
	.tabs{
		height: calc(100% - 100px);
		.tab{
			height: 100%;
		} 
	}
}

// store-card
.store-card{
	overflow: hidden;
	border-radius: 22px;
	box-shadow: 0 0 30px rgba(0,0,0,0.2);
	background-color: var(--f7-theme-color-white);
	
	.card-media{
		position: relative;
		img{
			width: 100%;
			height: 100%;
			display: flex;
			object-fit: cover;
		}
	}
	.card-info{
		padding: 20px;
	}
	.title{
		margin: 0;
	}
	.info-bottom{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.location{
		font-size: 14px;
	}
	.item{
		margin: 10px 0;
	}
	.badge{
		color: #d94e00;
		position: absolute;
		background-color: #ffcfa7;
		bottom: 15px;
		left: 15px;
		padding: 12px 18px;
		height: auto;
		font-size: 14px;
		border-radius: 30px;
		line-height: 14px;
		box-shadow: 0 0 10px rgba(255, 207, 167, 0.2);
	}
	
	&.card-list{
		display: flex;
		.card-media{
			width: 120px;
			max-width: 120px;
			min-width: 120px;
		}
	}
}
.store-swiper{
	margin-left: -30px;
	margin-top: auto;
    margin-bottom: 30px;
	
	.swiper-slide{
		width: 220px;
		&:first-child {
			margin-left: 30px;
		}
	}
	@include respond('tab-land') {
		margin-right: -30px;
	}
}

// Location Tracking
.map-dir{
	box-shadow: 0 0 10px rgba(0,0,0,0.2);
	padding: 15px 25px;
    background-color: var(--f7-theme-color-white);
    display: inline-block;
    border-radius: 22px;
	position: relative;
	&:after{
		content: "";
		background-color: inherit;
		height: 20px;
		width: 20px;
		position: absolute;
		right: 18px;
		top: -6px;
		transform: rotate(45deg);
	}
}
.location-tracking{
	.navbar{
		background-color: rgba(255,255,255,0.7);
	}
	iframe{
	    height: 100%;
		width: 100%;
		border: 0;
		margin-bottom: -60px;
	}
	.fixed-content{
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
	}
	.track-content{
		position: absolute;
		bottom: 245px;
		left: 0;
		right: 0;
	}
	.map-dir{
		position: absolute;
		left: 70px;
		top: -140px;
	}
}

.delivery-man{
	padding: 15px 30px 15px 15px;
    background-color: var(--f7-theme-color-white);
    display: flex;
    border-radius: 22px;
    align-items: center;
	box-shadow: 0 0 10px rgba(0,0,0,0.2);
	max-width: 320px;
    margin: 0 auto;
	
	.dz-media{
		height: 50px;
		width: 50px;
		border-radius: 50%;
		overflow: hidden;
		margin-right: 20px;
		
		img{
			width: 100%;
		}
	}
	h6,
	p{
		margin: 0;
	}
	.icon-cell{
		margin-left: auto;
	}
}
.track-list{
	padding: 0;
    margin: 0;
	position: relative;
	
	li{
		display: flex;
		align-items: center;
		margin-bottom: 30px;
		.icon-bx{
			height: 58px;
			width: 58px;
			text-align: center;
			line-height: 58px;
			border-radius: 50px;
			border: 1px solid;
			border-color: var(--f7-theme-color);
			margin-right: 20px;
			background-color: var(--f7-theme-color-white);
			
			svg{
				height: 58px;
			}
			&.location{
				background-color: var(--f7-theme-color-light);
				border-color: var(--f7-theme-color-light);
			}
			path[fill],
			path{
				fill: var(--f7-theme-color);
			}
			i{
				color: var(--f7-theme-color);
			}
		}
		.title{
			margin: 0;
		}
		p{
			margin: 0;
			font-size: 12px;
		}
		
		&:last-child{
			margin-bottom: 0;
		}
		.button{
			text-transform: capitalize;
			font-weight: 700;
			margin-left: auto;
		}
	}
	&:after{
		content: "";
		height: calc(100% - 10px);
		left: 30px;
		top: 5px;
		width: 1px;
		border-left: 2px dashed rgba(0,0,0,0.3);
		position: absolute;
		z-index: -1;
	}
}

// svg-primary
.svg-primary{
	[fill]{
		fill: var(--f7-theme-color);
	}
}