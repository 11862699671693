/* On Boading */
.footer-button {
	height: 92px;
    background-color: #fff;
    padding: 15px 30px !important;
    right: 0;
    left: 0;
	
	&:after, 
	&:before{
		content:none;
	}
	
}

.page,
.page-content{
	background:#fff;
}
.onboading-logo{
	margin: 25px auto;
    text-align: center;
	
	img{
		width:70px;
	}
}

// Logo
.view-logo{
	&.text-center{
		.logo-icon{
			margin: auto;
		}
	}
	.logo-icon{
	    height: 93px;
		width: 93px;
		background-color: #fff;
		border-radius: 50%;
		text-align: center;
		box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
		position: relative;
		line-height: 93px;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 2;
	
		&:after{
			content: "";
			background-color: inherit;
			height: 25px;
			width: 25px;
			position: absolute;
			bottom: -1px;
			transform: rotate(83deg);
			left: 10px;
			z-index: 0;
		}
		svg{
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
		}
		.color1[stop-color]{
			stop-color: var(--f7-theme-color-tint);
		}
		.color2[stop-color]{
			stop-color: var(--f7-theme-color-shade);
		}
	}
	.logo-name{
		margin-top: 20px;
	}
	.logo-text{
		font-size: 14px;
		margin: 0;
	}
}

.view-main{
	.page-onboading{
		.page-content {		
			display: flex;
			flex-direction: column;
			padding: 70px 0 150px;
			text-align: center;
			background-color: var(--f7-theme-color);
		}

		.footer-button{
			background:transparent;
			height: auto;
			
			.login-btn{
				background-color: var(--f7-theme-color-tint);
				margin-top: 18px;
			}
		}
		.view-logo{
			.logo-text{
				color: #fff;
			}
		}
		.start-btn {
			position: absolute;
			left: 50%;
			bottom: 20px;
			transform: translateX(-50%);
			z-index: 1;
		}
		@media (max-width: 361px){
			.page-content {		
				padding: 30px 0 140px;
			}
			.dz-media{
				width: 230px;
				margin: 0 auto 15px;
				img{
					width: 100%;
				}
			}
		}
	}
}

// swiper box
.started-swiper-box{
	height: 100%;
	max-width: 1024px;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	
	.swiper-slide{
		height: 100%;
		position: relative;
	}
	.slide-info{
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		box-sizing: border-box;
		padding-bottom: 50px;
		background-repeat: no-repeat;
		background-position: top left;
		background-size: 100%;
		z-index: 1;
		position: relative;
	}
	.view-logo{
		margin: auto;
	}
	.view-logo + .slide-content{
		margin-top: 0;
	}
	.slide-content{
		z-index: 1;
		position: relative;
		padding: 0 25px;
		max-width: 300px;
		margin: auto auto 0;
	}
	.bg-icon{
		position: absolute;
		left: 0;
		top: 50%;
		opacity: 0.1;
		transform: translateY(-50%);
	}
	@media (max-width: 361px){
		.view-logo{
			margin: 20px auto;
		}
	}
}

/* Get Started */
.get-started{
	text-align:center;
	height: 100%;
	.swiper-wrapper{
		margin-bottom:15px;
	}
	.dz-title{
		margin-top: 0;
		margin-bottom: 20px;
		color: #fff;
	}
	p{
		margin-bottom: 0;
		margin-top: 0;
		color: #fff;
	}

	.swiper-pagination{
		bottom: 0;
		opacity: 1;
		
		.swiper-pagination-bullet {
			margin: 0 4px;
			width: 10px;
			border-radius: 5px;
			height: 10px;
			background:rgba(255,255,255,0.3);
			opacity:1;
			@include transitionMedium;
			
			&-active{
				background:#fff;
			}
		}
	}
	@media (max-width: 361px){
		.dz-title {
			margin-bottom: 15px;
		}
	}
}

// start-btn
.start-btn{
	margin: auto;
	width: 105px;
	height: 105px;
	position: relative;
	text-align: center;
	
	&:before,
	&:after{
		content: "";
		position: absolute;
		background-size: contain;
		background-repeat: no-repeat;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
	}
	&:before{
		background-image: url(../img/circle1.png);
		height: 80px;
		width: 80px;    
		animation: movex 5s infinite;
	}
	&:after{
		background-image: url(../img/circle2.png);
		height: 104px;
		width: 104px;
		animation: movey 5s infinite;
	}
	.icon-bx{
		width: 60px;
		height: 60px;
		line-height: 60px;
		background-color: #fff;
		border-radius: 50%;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		svg,
		img{
			height: 60px;
		}
		path[fill],
		path,
		circle{
			fill: var(--f7-theme-color);
		}
		i{
			color: var(--f7-theme-color);	
		}
	}
}