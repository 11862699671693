/* Messages Navbar */
.messages-navbar{
	&.navbar{
		.title{
			padding: 5px 0 5px 70px;
			text-align: left!important;
			
			.avatar{
				height: 50px;
				width: 50px;
				border-radius: 50%;
				position: absolute;
				left: 0;
				top: 3px;
			}
			.name{
				margin: 0;
			}
			.online{
				color: #09c36a;
			}
			p{
				font-size: 14px;
				margin: 0;
				opacity: 0.5;
				font-weight: 500;
			}
		}
		.right{
			.link{
				i{
					font-size: 18px;
					margin-left: auto;
				}
			}
		}
	}
}


/* Message Toolbar */
.messagebar{
	height: 100px;
	.toolbar-inner{
		border: 1px solid #dadada;
		height: 62px;
		border-radius: 22px;
		padding: 7px 7px 7px 20px;
		textarea{
		    max-height: 43px;
			height: 43px;
			padding: 10px 10px 10px 0px;
			border: 0px;
			font-size: 16px;
			min-height: 43px;
		}
		.icon-only{
			height: 46px;
			width: 46px;
			line-height: 46px;
			border-radius: 18px;
			font-size: 18px;
			color: var(--f7-theme-color);
			background-color: var(--f7-theme-color-light);
			text-align: center;
			opacity: 1!important;	
		}
	}
}

/* Message */
.messages{
	margin: 0 0 20px!important;
	
	.messages-title{
		font-weight: 600;
		position: relative;
		margin: 20px 0;
		
		&:before{
			content: "";
			width: 100%;
			height: 1px;
			position: absolute;
			top: 6px;
			background-color: #ebebeb;
			left: 0;
			z-index: -1;
		}
		span{
			background-color: var(--f7-theme-color-white);
			padding: 2px 8px;
		}
	}
	
	.message{
		margin: 15px 0 0!important;
		max-width: 84%;
		
		// Message Content
		.message-content{
			.message-bubble{
				font-size: 16px;
				padding: 18px;
				line-height: 22px;
				&:before{
					content: none;
				}
			}
			.message-name{
				display: none;
			}
		}
		
		// Message Received
		&.message-received{
			.message-content{
				.message-bubble{
					background: $secondary;
					color: #262626;
					border-radius: 22px 22px 22px 0;
				}
			}
		}
		
		// Message Received
		&.message-sent{
			.message-content{
				.message-bubble{
					color: #fff;
					border-radius: 22px 0 22px 22px;
				}
			}
		}
	}
}