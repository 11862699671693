.outlet-dashboard .page-content
{
    background: #EDEDF5 !important;
}
.outlet-dashboard  .containerCard{
    box-shadow: inherit;
}
.wallet-card-section:before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    /* content: ""; */
    display: block;
    height: 140px;
}

.wallet-card {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.09);
    border-radius: 10px;
    padding: 20px 24px;
    position: relative;
    z-index: 1;
    /* height: 62vh; */
}



.wallet-card .balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    height: 100px;
}

.wallet-card .balance .left {
    padding-right: 10px;
}

.wallet-card .balance .title {
    color: #27173E;
    font-weight: 500;
    display: block;
    margin-bottom: 8px;
}

.wallet-card .balance .total {
    font-weight: 700;
    letter-spacing: -0.01em;
    line-height: 1em;
    font-size: 40px;
}

.wallet-card .balance .right {
    padding: 0;
}

.wallet-card .button {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1em;
    color: #6236FF;
    background: rgba(98,54,255,0.1);
    width: 90px;
    height: 65px;
    font-size: 26px;
    border-radius: 10px;
}

:host .ionicon {
    stroke: currentcolor;
}

.ionicon-stroke-width {
    stroke-width: var(--ionicon-stroke-width, 32px);
}


.wallet-footer {
    border-top: 1px solid #DCDCE9;
    padding-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.wallet-footer .item {
    flex: 1;
    text-align: center;
}

.wallet-footer .item a {
    display: block;
}

.wallet-footer .item .icon-wrapper {
    /* background: #6236FF; */
    width: 65px;
    height: 65px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: #fff;
    font-size: 24px;
    margin-bottom: 14px;
}

.wallet-footer .card-overlay{
    padding: 0px;
}

.hydrated {
    visibility: inherit;
}

:host {
    display: inline-block;
    width: 1em;
    height: 1em;
    contain: strict;
    fill: currentcolor;
    box-sizing: content-box !important;
}

.ionicon-fill-none {
    fill: none;
}

.wallet-footer .item strong {
    display: block;
    color: #27173E;
    font-weight: 500;
    font-size: 11px;
    line-height: 1.2em;
}

.bg-danger {
    background: #FF396F !important;
    color: #FFF;
}

.bg-success {
    background: #1DCC70 !important;
    color: #FFF;
}

.bg-warning {
    background: #FFB400 !important;
    color: #FFF;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.09);
    border-radius: 10px;
    padding: 20px 24px;
}

.stat-box .title {
    font-size: 13px;
    color: #958d9e;
    font-weight: 500;
    display: block;
    margin-bottom: 8px;
    line-height: 1.4em;
}

.stat-box .value {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 1em;
    color: #27173E;
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
}

.col-4 {
    flex: 0 0 auto !important;
    width: 33% !important;
    padding-left: 1px;
    padding-right: 1px;
}

.text-success, a.text-success {
    color: #1DCC70 !important;
}

@media screen and (max-width: 767px){
    .col-4 { 
        width: 32% !important; 
    }
    .wallet-card .button { 
        width: 100%; 
    }
    .wallet-card{
        padding: 20px 10px;
    }
}