.wishlist-bx{
	position: relative;
	background-color: var(--f7-theme-color-white);
	overflow: hidden;
    border-radius: $border-radius;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
	display: flex;
	padding: 12px 25px 12px 12px;
	align-items: center;
	
	.dz-media{
		position: relative;
		width: 93px;
		min-width: 93px;
		border-radius: $border-radius;
		overflow: hidden;
		
		img{
		    width: 100%;
			height: 100%;
			display: flex;
			object-fit: cover;
		}
	}
	.dz-head{
		margin-bottom: 15px;
	}
	.dz-meta{
		ul{
			justify-content: space-between;
			background-color: transparent;
		}
	}
	.dz-info{
		padding: 0 0 0 15px;
		width: 100%;
	}
	.item-title{
		font-weight: 600;
		margin: 0 0 5px;
		white-space: normal;
	}
	.price{
		font-weight: 600;
		font-size: 18px;
		color: #000;
		del{
			font-size: 12px;
			font-weight: 500;
			color: #929292;
			margin-left: 8px;
		}
		&:after{
			display: none;
		}
	}
	.qty{
		font-size: 16px;
		line-height: 16px;
		margin-left: auto;
		
		strong{
			font-weight: 600;
			margin-right: 5px;
		}
	}
	.rating{
		display: flex;
		font-size: 12px;
		align-items: center;
		
		i{
			color: #FFA800;
			margin-right: 5px;
		}
	}
	.dz-footer{
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	p{
		font-size: 12px;
		margin: 0;
		font-weight: 500;
		line-height: 12px;
	}
}