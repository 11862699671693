.welcome-screen{
	background-size: contain;
    background-repeat: no-repeat;
	background-color: #f9e7e7;
	
	.page-content {		
		display: flex;
		flex-direction: column;
		padding: 0 0 200px;
		text-align: center;
		background-color: transparent;
	}
	.footer-button{
		background:transparent;
		height: auto;
		padding-bottom: 30px!important;
			
		.login-btn{
			background-color: var(--f7-theme-color-tint);
			margin-top: 18px;
		}
	}
	.view-logo{
		.logo-text{
			opacity: 0.7;
			color: #000;
		}
		.logo-name {
			font-size: 38px;
			color: #0c0c0c;
			letter-spacing: 8px;
			margin: 20px 0;
			line-height: 38px;
		}
	}
	.started-swiper-box{
		.swiper-slide {
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: top center;
		}
		.slide-info{
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			box-sizing: border-box;
			padding-bottom: 40px;
			background-repeat: no-repeat;
			background-position: top left;
			background-size: 100%;
			z-index: 1;
			position: relative;
			
			.dz-title{
				margin: 0;
			}
			
			&:after{
				content: "";
				height: 100%;
				width: 100%;
				left: 0;
				bottom: 0;
				z-index: -1;
				position: absolute;
				background: -moz-linear-gradient(top,  rgba(249,231,231,0.5) 1%, rgba(249,231,231,1) 95%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top,  rgba(249,231,231,0.5) 1%, rgba(249,231,231,1) 95%); /* Chrome10-25,Safari5.1-6 */
			}
		}
	}
}