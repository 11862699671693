/* Theme Dark */
.theme-dark{
	// Heading Color
	h1, h2, h3, h4, h5, h6,
	h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
	.navbar .navbar-inner .title,
	.nav-wizard li .nav-link span,
	.dz-accordion .accordion-item .item-link.item-content .item-title,
	.wishlist-bx .price{
		color: #fff;
	}
	body{
		color: #cecece;
	}	
}

/* Border Color */
.theme-dark{
	.navbar,
	.menubar-area,
	.border-top,
	.searchbar.search-box .searchbar-input-wrap input,
	.form-elements ul .item-content .item-inner .item-input-wrap .form-control,
	.categories-swiper .swiper-slide.swiper-slide-next .categories-bx,
	.messagebar .toolbar-inner,
	.categories-bx,
	.comment-list .comment .children,
	.page-content .searchbar.search-box .searchbar-input-wrap input,
	.form-elements ul .item-content .item-inner .item-input-wrap .number-select,
	.comment-list > ul > li,
	.search-list-bar ul li,
	.categories-bx .icon-bx,
	.reviews-info,
	.author-bx .phone,
	.notification-list ul li,
	.profile-bx,
	.item-details .stepper,
	.review-product,
	.track-list:after{
		border-color: #333333;		
	}
}
.theme-dark{
	.profile-bx .author{
		border-color: #121212;
	}
}

/* Background Color */
.theme-dark{
	body,
	.page,
	.page-content,
	.tab-style-1,
	.footer-button,
	.page-onboading .page-content,
	.rewards-card .badge-lg,
	.nav-wizard,
	.dz-accordion ul,
	.dz-accordion .accordion-item .item-link.item-content .item-title:before{
		background:#151515;
	}
}

/* Color Fill */
.theme-dark{
	.account-area .logo .logo-icon path[fill].white{
		fill:#151515;	
	}
}

/* Background Color */
.theme-dark{
	.navbar .navbar-bg{
		background:#151515!important;	
	}
}

/* Background Color Dark 2 */
.theme-dark{
	.view-logo .logo-icon,
	.searchbar.search-box .searchbar-input-wrap input,
	.tabbar.tab-style-1 .toolbar-inner,
	.button.button-gray,
	.item-bx .cart-btn,
	.filter-bx .filter-btn,
	.store-card,
	.rewards-card,
	.wishlist-bx{
		background-color:#000;	
	}
}

/* Theme Color White */
.theme-dark{
	.text-black,
	.form-elements ul .item-content .item-inner .item-input-wrap .form-control,
	.navbar.navbar-transparent.navbar-transparent-visible .title, 
	.navbar.navbar-transparent.navbar-transparent-visible a, 
	.navbar.navbar-transparent.navbar-transparent-visible i,
	.navbar a.link,
	.tab-style-1 .tab-link.tab-link-active,
	.search-list-bar .item-content .item-inner .item-title-row .item-title,
	.tabbar.tab-style-1 .tab-link.tab-link-active,
	.welcome-screen .view-logo .logo-text,
	.welcome-screen .view-logo .logo-name,
	.notification-bar span,
	.item-details .stepper input,
	.tabbar.tab-style-2 .tab-link,
	.panel-close i{
		color:#cecece;
	}
}

/* Theme Dark Color Fill White */
.theme-dark{
	.profile-bx .profile-info .icon-bx path[fill], 
	.profile-bx .profile-info .icon-bx path,
	.navbar a.link path[fill],
	.navbar a.link path,
	.navbar a.link rect,
	.navbar a.link rect[fill],
	.navbar a.link [fill]{
		fill: #fff;
	}
}

/* Theme Color Second */
.theme-dark{
	.check-list li,
	.search-list-bar .item-content .item-inner .item-text,
	.form-elements ul .item-content .item-inner .item-input-wrap .form-control::placeholder,
	.account-area p,
	.account-area .form-text,
	.comment-list .comment .comment-text,
	.comment-list .comment .comment-meta ul li a{
		color:#969696;
	}
}

/* Heading Color */
.theme-dark{
	.card-overlay.style-1 .title a{
		color: #222;
	}
}

/* Background Color White */
.theme-dark{
	.swiper-pagination-bullet{
		background: #fff;
	}
}

.theme-dark{
	.welcome-screen .started-swiper-box .slide-info:after {
		background: -moz-linear-gradient(top, rgba(249, 231, 231, 0.5) 1%, #151515 95%);
		background: -webkit-linear-gradient(top, rgba(249, 231, 231, 0.5) 1%, #151515 95%);
	}
}

.theme-dark .location-tracking .navbar{
	background-color: rgba(0,0,0,0.7);
}
.theme-dark{
	.nav-wizard:after {
		background: -moz-linear-gradient(left, #151515 0%, rgba(0, 0, 0, 0) 54%, #151515 100%);
		background: -webkit-linear-gradient(left, #151515 0%, rgba(0, 0, 0, 0) 54%, #151515 100%);
		background: linear-gradient(to right, #151515 0%, rgba(0, 0, 0, 0) 54%, #151515 100%);
	}
}

/* Theme Color Dark Variable */
.ios .theme-dark,
.ios.theme-dark {
	--f7-text-color: #fff;
	--f7-bars-bg-color: #121212;
	--f7-bars-bg-color-rgb: 22, 22, 22;
	--f7-bars-border-color: rgba(255, 255, 255, 0.16);
    --f7-page-bg-color: #040e01;
}
:root .theme-dark,
:root.theme-dark,
:root.md.theme-dark{
	--f7-bars-text-color: #fff;
	--f7-block-title-text-color: #fff;
	--f7-block-strong-border-color: rgba(255, 255, 255, 0.15);
	--f7-block-title-medium-text-color: #fff;
	--f7-block-title-large-text-color: #fff;
	--f7-block-strong-bg-color: #030b00;
	--f7-list-button-border-color: rgba(255, 255, 255, 0.15);
	--f7-list-bg-color: #030b00;
	--f7-list-border-color: rgba(255, 255, 255, 0.15);
	--f7-list-item-border-color: rgba(255, 255, 255, 0.15);
	--f7-list-item-divider-border-color: rgba(255, 255, 255, 0.15);
	--f7-list-item-divider-bg-color: #232323;
	--f7-list-group-title-bg-color: #232323;
	--f7-list-chevron-icon-color: rgba(255, 255, 255, 0.3);
	--f7-card-bg-color: #030b00;
	--f7-bars-bg-color: #030b00;
	--f7-theme-color-light: #040e01;
	--f7-theme-color-white: #151515;
}