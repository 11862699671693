/* button loader */
.loader-screen {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    background-color: var(--f7-theme-color);	
}

// Loading Area
#loading-area {
	margin: auto;
	width: 105px;
	height: 105px;
	position: relative;
	
	&:before,
	&:after{
		content: "";
		position: absolute;
		background-size: contain;
		background-repeat: no-repeat;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
	}
	&:before{
		background-image: url(../img/circle1.png);
		height: 80px;
		width: 80px;    
		animation: movex 5s infinite;
	}
	&:after{
		background-image: url(../img/circle2.png);
		height: 104px;
		width: 104px;
		animation: movey 5s infinite;
	}
	div{
		width: 50px;
		background-color: #fff;
		height: 50px;
		border-radius: 50%;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		animation: scalediv 5s infinite;
	}
}

// Loading Animated
@keyframes movex {
	50%{
		transform: translate(-50%,-50%) rotate(180deg);
	}
}
@keyframes movey {
	50%{
		transform: translate(-50%,-50%) rotate(-180deg);
	}
}
@keyframes scalediv{
	50%{
		transform: translate(-50%,-50%) scale(1.1);
	}
}
